import React from 'react';
import { graphql } from 'gatsby';
import { ErrorQuery } from '@types';
import { adaptImage } from '@src/adapters/image';
import { PageNotFound } from '@src/ui';

interface Props {
  data: ErrorQuery;
}

const Page: React.FC<Props> = ({ data }) => {
  return <PageNotFound errorImage={adaptImage(data.errorImage)} />;
};

export const query = graphql`
  query Error {
    errorImage: file(relativePath: { eq: "error.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Page;
